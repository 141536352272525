import React from "react";
import * as footerStyles from "./footer.module.css"

const Footer = ({ title }) => (
    <div className={footerStyles.wrapper}>
        <div className={footerStyles.content}>
            <p>
              All work © {title}, {new Date().getFullYear()}
            </p>
            <div id="wcb" className="carbonbadge"></div>
        </div>
    </div>
)

export default Footer;
