import React from "react";
import * as headerStyles from "./header.module.css"
import { Link } from "gatsby";
import { withPrefix } from 'gatsby'

const Header = ({ title }) => (
    <div className={headerStyles.wrapper}>
        <div className={headerStyles.content}>
            <div className={headerStyles.home}>
              <Link to="/">
                {title}
              </Link>
            </div>
            <nav className={headerStyles.navigation}>
                <Link
                    activeClassName={headerStyles.active}
                    to="/">
                    Work
                </Link>
                <Link
                    activeClassName={headerStyles.active}
                    to="/about/">
                    About
                </Link>
                <a
                  rel="noopener noreferrer"
                  href={withPrefix('/resume.pdf')}
                  target="_blank">Resume
                </a>
            </nav>
        </div>
    </div>
)

export default Header;
