import * as React from "react"
import Header from "./header";
import Footer from "./footer";
import * as layoutStyles from "./layout.module.css"


const Layout = ({ location, title, children }) => {
  const rootPath = `${__PATH_PREFIX__}/`
  var isRootPath = false
  if (location !== undefined && location !== null) {
    isRootPath = location.pathname === rootPath
  }

  return (
    <div className={layoutStyles.globalWrapper}>
      <Header title={title}/>
      <div className={layoutStyles.contentWrapper} data-is-root-path={isRootPath}>
        <main>{children}</main>
      </div>
      <Footer title={title}/>
    </div>
  )
}

export default Layout
