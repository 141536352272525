/**
 * Write-only the password as cookie
 */
import React, { useState } from 'react';
import { setSessionPassword } from '@mkitio/gatsby-theme-password-protect/src/utils/utils';
import Layout from "../../../components/layout"
import { Link } from "gatsby"

const styles = {
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
    margin: 'auto',
    marginTop: '80px',
    maxWidth: '500px',
  },
  input: {
    width: '100%',
    height: '48px',
    borderRadius: '4px',
  },
  button: {
    width: '100%',
    height: '48px',
    background: '#43614B',
    color: '#fff',
    border: '1px solid #43614B',
    borderRadius: '4px',
    marginTop: '16px',
    textTransform: 'uppercase',
    transition: 'all .3s ease',
  },
  buttonHover: {
    opacity: '.7',
  },
};

const PasswordProtect = () => {
  const [password, setPassword] = useState('');
  const [isButtonHovered, buttonHover] = useState(false);

  const onSubmit = event => {
    event.preventDefault();
    setSessionPassword(password);
    window.location.reload(); // eslint-disable-line
  };

  return (
    <Layout location={null} title="Qiqi Xu">
    <div style={styles.wrapper}>
      <h3>🔒 Enter Password 🔒</h3>
      <p>This portfolio piece is currently not available to the public. Check back later or <Link to="/about">contact me</Link> to get access.</p>

      <form onSubmit={onSubmit}>
        <input
          name="password"
          type="password"
          value={password}
          onChange={event => setPassword(event.target.value)}
          style={styles.input}
        />

        <button
          type="submit"
          style={{
            ...styles.button,
            ...(isButtonHovered ? styles.buttonHover : null)
          }}
          onMouseEnter={() => buttonHover(true)}
          onMouseLeave={() => buttonHover(false)}
        >
          Let's go 🔥
        </button>
      </form>
    </div>
    </Layout>
  );
};

export default PasswordProtect;
